/* eslint-disable import/no-unresolved */
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/home'));
const SettingsPage = lazy(() => import('src/pages/dashboard/settings'));
const ContactUsPage = lazy(() => import('src/pages/dashboard/contact-us'));
const CreateLicensePage = lazy(() => import('src/pages/dashboard/create-license'));
// ENTRA MANAGER
const CreateAccountPage = lazy(() => import('src/pages/dashboard/entra-manager/create-account'));
const AccountDetailPage = lazy(() => import('src/pages/dashboard/entra-manager/account-detail'));
const LicensesPage = lazy(() => import('src/pages/dashboard/entra-manager/licenses'));
const DomainAccountsPage = lazy(() => import('src/pages/dashboard/entra-manager/domain-accounts'));
const GuestAccountsPage = lazy(() => import('src/pages/dashboard/entra-manager/guest-accounts'));
const OnPremSyncStatus = lazy(() => import('src/pages/dashboard/entra-manager/sync-status'));
// ENTRA REPORTING
const NonMFAPage = lazy(() => import('src/pages/dashboard/entra-reporting/non-mfa'));
// SHAREPOINT
const CreateSitePage = lazy(() => import('src/pages/dashboard/sharepoint-manager/create-site'));
const SiteDetailPage = lazy(() => import('src/pages/dashboard/sharepoint-manager/site-detail'));
const SiteListPage = lazy(() => import('src/pages/dashboard/sharepoint-manager/site-list'));
// TEAMS
const UnusedTeamsPage = lazy(() => import('src/pages/dashboard/teams-manager/unused-teams'));
const SitesWithGuestsPage = lazy(() => import('src/pages/dashboard/teams-manager/sites-with-guests'));
const OrphanedTeamsPage = lazy(() => import('src/pages/dashboard/teams-manager/orphaned-teams'));
const TeamsDetailPage = lazy(() => import('src/pages/dashboard/teams-manager/teams-detail'));
const AppsPage = lazy(() => import('src/pages/dashboard/teams-manager/apps'));
// EXCHANGE
const EmailSignaturePage = lazy(() => import('src/pages/dashboard/exchange-manager/email-signature'));
const CreateSharedMailboxPage = lazy(() => import('src/pages/dashboard/exchange-manager/create-shared-mailbox'));
const InactiveMailboxesPage = lazy(() => import('src/pages/dashboard/exchange-manager/inactive-mailboxes'));
const DmarcDkimPage = lazy(() => import('src/pages/dashboard/exchange-manager/dmarc-dkim'));
// SECURITY MANAGER
const NewTrustedLocationPage = lazy(() => import('src/pages/dashboard/security-manager/new-trusted-location'));
const TrustedLocationsPage = lazy(() => import('src/pages/dashboard/security-manager/trusted-locations'));
const SignInLogsPage = lazy(() => import('src/pages/dashboard/security-manager/sign-in-logs'));
const AuditLogsPage = lazy(() => import('src/pages/dashboard/security-manager/audit-logs'));
const ConditionalAccessPage = lazy(() => import('src/pages/dashboard/security-manager/conditional-access'));
const RiskySignInsPage = lazy(() => import('src/pages/dashboard/security-manager/risky-sign-ins'));
const RiskyWorkloadIdsPage = lazy(() => import('src/pages/dashboard/security-manager/risky-workload-ids'));
const RiskyUsersPage = lazy(() => import('src/pages/dashboard/security-manager/risky-users'));
// SECURITY REPORTING
const ScorePage = lazy(() => import('src/pages/dashboard/security-reporting/score'));
const ScoreProfilesPage = lazy(() => import('src/pages/dashboard/security-reporting/score-profiles'));
// XDR
const MalwarePolicyPage = lazy(() => import('src/pages/dashboard/xdr-manager/malware-policy'));
const SafeLinksPage = lazy(() => import('src/pages/dashboard/xdr-manager/safe-links'));
const SafeAttachmentsPage = lazy(() => import('src/pages/dashboard/xdr-manager/safe-attachments'));
const AntiPhishPolicyPage = lazy(() => import('src/pages/dashboard/xdr-manager/anti-phish-policy'));
const AntiSpamPolicyPage = lazy(() => import('src/pages/dashboard/xdr-manager/anti-spam-policy'));
const AlertsPage = lazy(() => import('src/pages/dashboard/xdr-manager/alerts'));
const InventoryPage = lazy(() => import('src/pages/dashboard/xdr-manager/inventory'));
const RemediationsPage = lazy(() => import('src/pages/dashboard/xdr-manager/remediations'));
const VulnerabilitiesPage = lazy(() => import('src/pages/dashboard/xdr-manager/vulnerabilities'));
// INTUNE
const AntiVirusPolicyPage = lazy(() => import('src/pages/dashboard/intune-manager/anti-virus-policy'));
const FirewallPolicyPage = lazy(() => import('src/pages/dashboard/intune-manager/firewall-policy'));
const ASRPolicyPage = lazy(() => import('src/pages/dashboard/intune-manager/asr-policy'));
// GROUPS
const SecurityGroupsPage = lazy(() => import('src/pages/dashboard/group-manager/security-groups'));
const DistributionListPage = lazy(() => import('src/pages/dashboard/group-manager/distribution-list'));
const MS365GroupsPage = lazy(() => import('src/pages/dashboard/group-manager/365-groups'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'settings', element: <SettingsPage /> },
      { path: 'contact-us', element: <ContactUsPage /> },
      { path: 'create-license', element: <CreateLicensePage /> },
      {
        path: 'entra-manager',
        children: [
          { element: <CreateAccountPage />, index: true },
          { path: 'create-account', element: <CreateAccountPage /> },
          { path: 'account-detail', element: <AccountDetailPage /> },
          { path: 'domain-accounts', element: <DomainAccountsPage /> },
          { path: 'guest-accounts', element: <GuestAccountsPage /> },
          { path: 'licenses', element: <LicensesPage /> },
          {path:"sync-status",element: <OnPremSyncStatus />}
        ],
      },
      {
        path: 'entra-reporting',
        children: [
          { element: <NonMFAPage />, index: true },
          { path: 'non-mfa', element: <NonMFAPage /> },
        ],
      },
      {
        path: 'sharepoint-manager',
        children: [
          { element: <CreateSitePage />, index: true },
          { path: 'create-site', element: <CreateSitePage /> },
          { path: 'site-detail', element: <SiteDetailPage /> },
          { path: 'site-list', element: <SiteListPage /> },
        ],
      },
      {
        path: 'teams-manager',
        children: [
          { element: <UnusedTeamsPage />, index: true },
          { path: 'orphaned-teams', element: <OrphanedTeamsPage /> },
          { path: 'sites-with-guests', element: <SitesWithGuestsPage /> },
          { path: 'unused-teams', element: <UnusedTeamsPage /> },
          { path: 'teams-detail', element: <TeamsDetailPage /> },
          { path: 'apps', element: <AppsPage /> },
        ],
      },
      {
        path: 'exchange-manager',
        children: [
          { element: <EmailSignaturePage />, index: true },
          { path: 'create-shared-mailbox', element: <CreateSharedMailboxPage /> },
          { path: 'dmarc-dkim', element: <DmarcDkimPage /> },
          { path: 'email-signature', element: <EmailSignaturePage /> },
          { path: 'inactive-mailboxes', element: <InactiveMailboxesPage /> },
        ],
      },
      {
        path: 'security-manager',
        children: [
          { element: <NewTrustedLocationPage />, index: true },
          { path: 'audit-logs', element: <AuditLogsPage /> },
          { path: 'conditional-access', element: <ConditionalAccessPage /> },
          { path: 'new-trusted-location', element: <NewTrustedLocationPage /> },
          { path: 'risky-sign-ins', element: <RiskySignInsPage /> },
          { path: 'risky-users', element: <RiskyUsersPage /> },
          { path: 'risky-workload-ids', element: <RiskyWorkloadIdsPage /> },
          { path: 'sign-in-logs', element: <SignInLogsPage /> },
          { path: 'trusted-locations', element: <TrustedLocationsPage /> },
        ],
      },
      {
        path: 'security-reporting',
        children: [
          { element: <ScorePage />, index: true },
          { path: 'score', element: <ScorePage /> },
          { path: 'score-profiles', element: <ScoreProfilesPage /> },
        ],
      },
      {
        path: 'xdr-manager',
        children: [
          { element: <MalwarePolicyPage />, index: true },
          { path: 'alerts', element: <AlertsPage /> },
          { path: 'anti-phish-policy', element: <AntiPhishPolicyPage /> },
          { path: 'anti-spam-policy', element: <AntiSpamPolicyPage /> },
          { path: 'inventory', element: <InventoryPage /> },
          { path: 'malware-policy', element: <MalwarePolicyPage /> },
          { path: 'remediations', element: <RemediationsPage /> },
          { path: 'safe-attachments', element: <SafeAttachmentsPage /> },
          { path: 'safe-links', element: <SafeLinksPage /> },
          { path: 'vulnerabilities', element: <VulnerabilitiesPage /> },
        ],
      },
      {
        path: 'intune-manager',
        children: [
          { element: <AntiVirusPolicyPage />, index: true },
          { path: 'anti-virus-policy', element: <AntiVirusPolicyPage /> },
          { path: 'asr-policy', element: <ASRPolicyPage /> },
          { path: 'firewall-policy', element: <FirewallPolicyPage /> },
        ],
      },
      {
        path: 'group-manager',
        children: [
          { element: <SecurityGroupsPage />, index: true },
          { path: '365-groups', element: <MS365GroupsPage /> },
          { path: 'security-groups', element: <SecurityGroupsPage /> },
          { path: 'distribution-list', element: <DistributionListPage /> },
        ],
      },
    ],
  },
];
