/* eslint-disable import/no-unresolved */
import { useMemo } from 'react';

// eslint-disable-next-line import/no-unresolved
import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';

// eslint-disable-next-line import/no-unresolved
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

// const shIcon = (name) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 16, height: 16 }} />
//   // OR
//   // <Iconify icon="fluent:mail-24-filled" />
//   // https://icon-sets.iconify.design/solar/
//   // https://www.streamlinehq.com/icons
// );

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('dashboard'),
  defender: icon('defender'),
  exchange: icon('exchange'),
  teams: icon('teams'),
  sharepoint: icon('sharepoint'),
  intune: icon('intune'),
  security: icon('security-center'),
  entra: icon('entra'),
  reporting: icon('report'),
  setting: icon('setting'),
  help: icon('help-and-support'),
};

// const ICONSSH = {
//   defender: shIcon('defender'),
//   exchange: shIcon('exchange'),
//   teams: shIcon('teams'),
//   sharepoint: shIcon('sharepoint'),
//   intune: shIcon('intune'),
// };

// ----------------------------------------------------------------------

export function useNavData() {
  const { inthouseUser, isSetupCompleted } = useAuthContext();

  const { t } = useTranslate();

  const data = useMemo(
    () =>
      isSetupCompleted
        ? [
            // OVERVIEW
            // ----------------------------------------------------------------------
            {
              subheader: 'admin toolkit',
              items: [
                { title: 'dashboard', path: paths.dashboard.root, icon: ICONS.ecommerce },
                // { title: 'how to use', path: paths.dashboard.two, icon: ICONS.dashboard },
              ],
            },

            // ADMIN
            // ----------------------------------------------------------------------
            inthouseUser
              ? {
                  subheader: 'admin',
                  items: [
                    {
                      title: t('create-license'),
                      path: paths.dashboard.createLicense,
                      icon: ICONS.ecommerce,
                    },
                  ],
                }
              : {
                  subheader: '',
                  items: [],
                },
            // ENTRA
            // ----------------------------------------------------------------------
            {
              subheader: 'entra',
              items: [
                {
                  title: 'entra manager',
                  path: paths.dashboard.entraManager.root,
                  icon: ICONS.entra,
                  children: [
                    {
                      title: t('entra.create-account'),
                      path: paths.dashboard.entraManager.createAccount,
                    },
                    {
                      title: t('entra.account-detail'),
                      path: paths.dashboard.entraManager.accountDetail,
                    },
                    {
                      title: t('entra.domain-accounts'),
                      path: paths.dashboard.entraManager.domainAccounts,
                    },
                    {
                      title: t('entra.guest-accounts'),
                      path: paths.dashboard.entraManager.guestAccounts,
                    },
                    { title: t('entra.licenses'), path: paths.dashboard.entraManager.licenses },
                  ],
                },
                {
                  title: t('entra.reporting'),
                  path: paths.dashboard.entraReporting.root,
                  icon: ICONS.reporting,
                  children: [
                    { title: t('entra.non-mfa'), path: paths.dashboard.entraReporting.nonMFA },
                  ],
                },
              ],
            },
            // EXCHANGE
            // ----------------------------------------------------------------------
            {
              subheader: 'exchange',
              items: [
                {
                  title: 'exchange manager',
                  path: paths.dashboard.exchangeManager.root,
                  icon: ICONS.exchange,
                  children: [
                    {
                      title: t('exchange.create-shared-mailbox'),
                      path: paths.dashboard.exchangeManager.createSharedMailbox,
                    },
                    {
                      title: t('exchange.dmarc-dkim'),
                      path: paths.dashboard.exchangeManager.dmarcDkim,
                    },
                    {
                      title: t('exchange.email-signature'),
                      path: paths.dashboard.exchangeManager.emailSignature,
                    },
                    {
                      title: t('exchange.inactive-mailboxes'),
                      path: paths.dashboard.exchangeManager.inactiveMailboxes,
                    },
                  ],
                },
              ],
            },
            // SHAREPOINT
            // ----------------------------------------------------------------------
            {
              subheader: 'sharepoint',
              items: [
                {
                  title: 'sharepoint manager',
                  path: paths.dashboard.sharepointManager.root,
                  icon: ICONS.sharepoint,
                  children: [
                    {
                      title: t('sharepoint.create-site'),
                      path: paths.dashboard.sharepointManager.createSite,
                    },
                    {
                      title: t('sharepoint.site-detail'),
                      path: paths.dashboard.sharepointManager.siteDetail,
                    },
                    {
                      title: t('sharepoint.site-list'),
                      path: paths.dashboard.sharepointManager.siteList,
                    },
                  ],
                },
              ],
            },
            // TEAMS
            // ----------------------------------------------------------------------
            {
              subheader: 'teams',
              items: [
                {
                  title: 'teams manager',
                  path: paths.dashboard.teamsManager.root,
                  icon: ICONS.teams,
                  children: [
                    {
                      title: t('teams.orphaned-teams'),
                      path: paths.dashboard.teamsManager.orphanedTeams,
                    },
                    {
                      title: t('teams.sites-with-guests'),
                      path: paths.dashboard.teamsManager.sitesWithGuests,
                    },
                    {
                      title: t('teams.unused-teams'),
                      path: paths.dashboard.teamsManager.unusedTeams,
                    },
                    {
                      title: t('teams.teams-detail'),
                      path: paths.dashboard.teamsManager.teamsDetail,
                    },
                    {
                      title: t('teams.apps'),
                      path: paths.dashboard.teamsManager.apps,
                    },
                  ],
                },
              ],
            },
            // DEFENDER FOR 365
            // ----------------------------------------------------------------------
            {
              subheader: 'defender for 365',
              // subheaderIcon: ICONSSH.defender,
              items: [
                {
                  title: 'XDR manager',
                  path: paths.dashboard.xdrManager.root,
                  icon: ICONS.defender,
                  children: [
                    { title: t('alerts'), path: paths.dashboard.xdrManager.alerts },
                    { title: t('anti-phish'), path: paths.dashboard.xdrManager.antiPhish },
                    { title: t('anti-spam'), path: paths.dashboard.xdrManager.antiSpam },
                    // { title: 'inventory', path: paths.dashboard.xdrManager.inventory },
                    { title: t('malware-policy'), path: paths.dashboard.xdrManager.malwarePolicy },
                    // { title: 'remediations', path: paths.dashboard.xdrManager.remediations },
                    {
                      title: t('safe-attachments'),
                      path: paths.dashboard.xdrManager.safeAttachments,
                    },
                    { title: t('safe-links'), path: paths.dashboard.xdrManager.safeLinks },
                    // { title: 'vulnerabilities', path: paths.dashboard.xdrManager.vulnerabilities },
                  ],
                },
              ],
            },
            // DEFENDER FOR ENDPOINT
            // ----------------------------------------------------------------------
            {
              subheader: 'defender for endpoint',
              items: [
                {
                  title: 'Intune Manager',
                  path: paths.dashboard.intuneManager.root,
                  icon: ICONS.intune,
                  children: [
                    { title: 'anti virus policy', path: paths.dashboard.intuneManager.antiVirus },
                    { title: 'asr policy', path: paths.dashboard.intuneManager.asrPolicy },
                    {
                      title: 'firewall policy',
                      path: paths.dashboard.intuneManager.firewallPolicy,
                    },
                  ],
                },
              ],
            },
            // SECURITY
            // ----------------------------------------------------------------------
            {
              subheader: 'security',
              items: [
                {
                  title: 'security manager',
                  path: paths.dashboard.securityManager.root,
                  icon: ICONS.security,
                  children: [
                    {
                      title: t('security.audit-logs'),
                      path: paths.dashboard.securityManager.auditLogs,
                    },
                    {
                      title: t('security.conditional-access'),
                      path: paths.dashboard.securityManager.conditionalAccess,
                    },
                    {
                      title: t('security.new-trusted-location'),
                      path: paths.dashboard.securityManager.newTrustedLocation,
                    },
                    {
                      title: t('security.risky-signins'),
                      path: paths.dashboard.securityManager.riskySignIns,
                    },
                    {
                      title: t('security.risky-users'),
                      path: paths.dashboard.securityManager.riskyUsers,
                    },
                    {
                      title: t('security.risky-workload-ids'),
                      path: paths.dashboard.securityManager.riskyWorkloadIds,
                    },
                    {
                      title: t('security.signin-logs'),
                      path: paths.dashboard.securityManager.signInLogs,
                    },
                    {
                      title: t('security.trusted-locations'),
                      path: paths.dashboard.securityManager.trustedLocations,
                    },
                  ],
                },
                {
                  title: t('security.reporting'),
                  path: paths.dashboard.securityReporting.root,
                  icon: ICONS.reporting,
                  children: [
                    { title: t('security.score'), path: paths.dashboard.securityReporting.score },
                    {
                      title: t('security.score-profiles'),
                      path: paths.dashboard.securityReporting.scoreProfiles,
                    },
                  ],
                },
              ],
            },
            // {
            //   subheader: 'group',
            //   items: [
            //     {
            //       title: 'Group Manager',
            //       path: paths.dashboard.groupManager.root,
            //       icon: ICONS.order,
            //       children: [
            //         { title: 'security groups', path: paths.dashboard.groupManager.securityGroups },
            //         { title: 'distribution list', path: paths.dashboard.groupManager.distributionList},
            //         { title: '365 groups', path: paths.dashboard.groupManager.ms365groups },
            //       ],
            //     },
            //   ],
            // },
            {
              subheader: t('settings'),
              items: [
                { title: t('settings'), path: paths.dashboard.settings, icon: ICONS.setting },
              ],
            },
            {
              subheader: t('contact-us-title'),
              items: [
                { title: t('contact-us'), path: paths.dashboard.contactUs, icon: ICONS.help },
              ],
            },
          ]
        : [
            {
              subheader: 'admin toolkit',
              items: [
                { title: 'dashboard', path: paths.dashboard.root, icon: ICONS.ecommerce },
                // { title: 'how to use', path: paths.dashboard.two, icon: ICONS.dashboard },
              ],
            },
            {
              subheader: t('settings'),
              items: [
                { title: t('settings'), path: paths.dashboard.settings, icon: ICONS.setting },
              ],
            },
            {
              subheader: t('contact-us-title'),
              items: [
                { title: t('contact-us'), path: paths.dashboard.contactUs, icon: ICONS.help },
              ],
            },
          ],
    [inthouseUser, t, isSetupCompleted]
  );

  return data;
}
