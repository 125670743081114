/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import PropTypes from 'prop-types';
import { useMemo, useState, useEffect, useReducer, useCallback } from 'react';

import { setSession } from './utils';
import { AuthContext } from './auth-context';

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isInthouseUser, setInthouseUser] = useState(false);
  const [tenantId, setTenantId] = useState();
  const [userData, setUserData] = useState();
  const [userDomain, setUserDomain] = useState();
  const [isSetupCompleted, setSetupCompleted] = useState();

  const initialize = useCallback(async () => {
    try {
      await fetch('../../api/auth/account').then((response) => {
        response.json().then((result) => {
          if (result && result.isAuthenticated) {
            const user = {};
            result.claims.forEach((obj) => {
              user[obj.type] = obj;
            });
            setUserData(user)
            setUserDomain(user.email.value.split('@')[1])
            if (user.tid.value === '3beebd7f-a1b7-4552-a41f-c58c2d151338') {
              setInthouseUser(true);
              setTenantId(user.tid.value)
            }
            setSetupCompleted(checkSetupCompleted())
            dispatch({
              type: 'INITIAL',
              payload: {
                user: {
                  ...result.claims,
                },
              },
            });
          } else {
            dispatch({
              type: 'INITIAL',
              payload: {
                user: null,
              },
            });
          }
        });
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (licenseCode) => {
    let url = "../../api/auth/login";

    const searchParams = new URLSearchParams({ "code": licenseCode });

    // if (postLoginRedirectUri) {
    //   searchParams.append('postLoginRedirectUri', encodeURIComponent(postLoginRedirectUri));
    // }

    // if (scopesToConsent) {
    //   searchParams.append('scopesToConsent', scopesToConsent.join(' '));
    // }

    url = `${url}?${searchParams.toString()}`;

    window.location.replace(url);

    // const response = await axios.post(endpoints.auth.login, data);

    // const { accessToken, user } = response.data;

    // setSession(accessToken);

    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user: {
    //       ...user,
    //       accessToken,
    //     },
    //   },
    // });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // const checkSetupCompleted = () => {
  //   axios.get(`/api/profile/setup`).then(
  //     (response) => setSetupCompleted(true),
  //     (error) => setSetupCompleted(false)
  //   );
  // }

  const checkSetupCompleted = useCallback(async () => {
    axios.get(`/api/profile/setup`).then(
      (response) => setSetupCompleted(true),
      (error) => setSetupCompleted(false)
    );
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      inthouseUser: isInthouseUser,
      tenantId,
      userData,
      userDomain,
      isSetupCompleted,
      checkSetupCompleted,
      //
      login,
      logout,
    }),
    [login, logout, checkSetupCompleted, state.user, status, isInthouseUser, tenantId, userData, userDomain, isSetupCompleted]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
