// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    one: `${ROOTS.DASHBOARD}/one`,
    two: `${ROOTS.DASHBOARD}/two`,
    three: `${ROOTS.DASHBOARD}/three`,
    settings: `${ROOTS.DASHBOARD}/settings`,
    contactUs: `${ROOTS.DASHBOARD}/contact-us`,
    createLicense: `${ROOTS.DASHBOARD}/create-license`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
    entraManager: {
      root: `${ROOTS.DASHBOARD}/entra-manager`,
      createAccount: `${ROOTS.DASHBOARD}/entra-manager/create-account`,
      accountDetail: `${ROOTS.DASHBOARD}/entra-manager/account-detail`,
      licenses: `${ROOTS.DASHBOARD}/entra-manager/licenses`,
      domainAccounts: `${ROOTS.DASHBOARD}/entra-manager/domain-accounts`,
      guestAccounts: `${ROOTS.DASHBOARD}/entra-manager/guest-accounts`,
      syncStatus: `${ROOTS.DASHBOARD}/entra-manager/sync-status`,
    },
    entraReporting: {
      root: `${ROOTS.DASHBOARD}/entra-reporting`,
      nonMFA: `${ROOTS.DASHBOARD}/entra-reporting/non-mfa`,
    },
    sharepointManager: {
      root: `${ROOTS.DASHBOARD}/sharepoint-manager`,
      createSite: `${ROOTS.DASHBOARD}/sharepoint-manager/create-site`,
      siteDetail: `${ROOTS.DASHBOARD}/sharepoint-manager/site-detail`,
      siteList: `${ROOTS.DASHBOARD}/sharepoint-manager/site-list`,
    },
    teamsManager: {
      root: `${ROOTS.DASHBOARD}/teams-manager`,
      unusedTeams: `${ROOTS.DASHBOARD}/teams-manager/unused-teams`,
      sitesWithGuests: `${ROOTS.DASHBOARD}/teams-manager/sites-with-guests`,
      orphanedTeams: `${ROOTS.DASHBOARD}/teams-manager/orphaned-teams`,
      teamsDetail: `${ROOTS.DASHBOARD}/teams-manager/teams-detail`,
      apps: `${ROOTS.DASHBOARD}/teams-manager/apps`,
    },
    exchangeManager: {
      root: `${ROOTS.DASHBOARD}/exchange-manager`,
      emailSignature: `${ROOTS.DASHBOARD}/exchange-manager/email-signature`,
      createSharedMailbox: `${ROOTS.DASHBOARD}/exchange-manager/create-shared-mailbox`,
      inactiveMailboxes: `${ROOTS.DASHBOARD}/exchange-manager/inactive-mailboxes`,
      dmarcDkim: `${ROOTS.DASHBOARD}/exchange-manager/dmarc-dkim`,
    },
    securityManager: {
      root: `${ROOTS.DASHBOARD}/security-manager`,
      newTrustedLocation: `${ROOTS.DASHBOARD}/security-manager/new-trusted-location`,
      trustedLocations: `${ROOTS.DASHBOARD}/security-manager/trusted-locations`,
      signInLogs: `${ROOTS.DASHBOARD}/security-manager/sign-in-logs`,
      signInLog: `${ROOTS.DASHBOARD}/security-manager/sign-in-log`,
      auditLogs: `${ROOTS.DASHBOARD}/security-manager/audit-logs`,
      conditionalAccess: `${ROOTS.DASHBOARD}/security-manager/conditional-access`,
      riskySignIns: `${ROOTS.DASHBOARD}/security-manager/risky-sign-ins`,
      riskyWorkloadIds: `${ROOTS.DASHBOARD}/security-manager/risky-workload-ids`,
      riskyUsers: `${ROOTS.DASHBOARD}/security-manager/risky-users`
    },
    securityReporting: {
      root: `${ROOTS.DASHBOARD}/security-reporting`,
      score: `${ROOTS.DASHBOARD}/security-reporting/score`,
      scoreProfiles: `${ROOTS.DASHBOARD}/security-reporting/score-profiles`,
    },
    xdrManager: {
      root: `${ROOTS.DASHBOARD}/xdr-manager`,
      safeLinks: `${ROOTS.DASHBOARD}/xdr-manager/safe-links`,
      safeAttachments: `${ROOTS.DASHBOARD}/xdr-manager/safe-attachments`,
      malwarePolicy: `${ROOTS.DASHBOARD}/xdr-manager/malware-policy`,
      antiPhish: `${ROOTS.DASHBOARD}/xdr-manager/anti-phish-policy`,
      antiSpam: `${ROOTS.DASHBOARD}/xdr-manager/anti-spam-policy`,
      alerts: `${ROOTS.DASHBOARD}/xdr-manager/alerts`,
      inventory: `${ROOTS.DASHBOARD}/xdr-manager/inventory`,
      remediations: `${ROOTS.DASHBOARD}/xdr-manager/remediations`,
      vulnerabilities: `${ROOTS.DASHBOARD}/xdr-manager/vulnerabilities`,
    },
    intuneManager: {
      root: `${ROOTS.DASHBOARD}/intune-manager`,
      antiVirus: `${ROOTS.DASHBOARD}/intune-manager/anti-virus-policy`,
      firewallPolicy: `${ROOTS.DASHBOARD}/intune-manager/firewall-policy`,
      asrPolicy: `${ROOTS.DASHBOARD}/intune-manager/asr-policy`,
    },
    groupManager: {
      root: `${ROOTS.DASHBOARD}/group-manager`,
      securityGroups: `${ROOTS.DASHBOARD}/group-manager/security-groups`,
      distributionList: `${ROOTS.DASHBOARD}/group-manager/distribution-list`,
      ms365groups: `${ROOTS.DASHBOARD}/group-manager/365-groups`,
    }
  },
};
